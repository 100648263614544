import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When we explain PayPerks to our friends and even some investors, the first question we often get is, "why wouldn’t someone have a bank account?" The shortest answer, and the one that's easiest for most banked people to understand, is that many people don’t have a spare \\$100 to maintain a bank's minimum balance requirement. We know this is only part of the story but often, that explanation alone is enough to answer someone's question. So we use that and then move on.`}</p>
    <p>{`Today I spotted `}<a parentName="p" {...{
        "href": "http://money.cnn.com/2011/09/13/news/economy/poverty_rate_income/index.htm?hpt=hp_t1"
      }}>{`this`}</a>{` story on CNN highlighting the rise of poverty in America and the infographic illustrating the poverty rate by state caught my eye. (It's the red map below.) When I juxtaposed it with the `}<a parentName="p" {...{
        "href": "http://www.fdic.gov/householdsurvey/"
      }}>{`FDIC infographic`}</a>{` (the blue map below) illustrating banked status by state, it became clear to me just how much we oversimplify the answer.`}</p>
    <p><strong parentName="p">{`Poverty Rate in America (in red) & Unbanked Rate (in blue)
`}</strong>{`The darker the color, the higher the percentage of poverty or unbanked`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "624px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACm0lEQVQoz22S208TQRTG+3/44h/ig08+6APRKAlKQA1KJEGEQLw8EAHTNIAWvISLFo0IaRoFSqtCLWKx9AJt1W1pKW0BW9x2263tbrs729mdHbNbEkH5Mg+Tk/llvnO+o0EIyVUhtH/HWBZFPp3J0nQmkykUixhj+T8hhDTq438lIyQCAIDAcZwgCPgoybK8D7M0TfoD+c1omaIkAVQEVZWKiJCk2sHqX8p3ooRVRIERQhjjtHctNDIWHh2PPn4afTay+/bdjn2JdHtyW3E2k4EAVO0hhCI2G88wVXcajLEIYfS5Idw/GB5+smUwJK3WxJup6Oh4wmiK2eyRhcXwzCwEgCNJwjAR93rpHM2yLMZYgUE+H+zuIbruRnQDqaXPWYL45XQmZud+Ltoybk9y+YtfP7Rrt0cmpzwP9VQ6nc3lCoXCPgwFIajr97W0RscM2e9E0vI+af2QmJnbNJo2Xkxs6IddXbfdHZ1O/VDQ5YKSlCbJfD6vwLLa8+7MrK+5xd96K9jdE+rTErqBH33asLY/0Pvgm1bnuHbD0daxeudedN6iOOV4nuNUWB2dBOGO0eSuvfj11GnHmRpPbZ2vqXm9vdN/v9dxoc7ZeHWl/rKrozO/vc0CUOL5Q1HJsixhzMTiSbMlPPjIXXPOf6nBX9+4eOKk6djxlYYry2fP59Z9hXKZWFompo0pi1WCUKMkiFCJypZommVYUZZhsZj6uBBouk7cbI8ZXkZevS7/LqS9a4k5MweAVCpBksxthCuCoAyMisXik9Mp8zwoMnuhkKDGQH6yU/5ANU8lYUnac65CAA5tGMa4kNqLmefJdR9DUVyxKIniwSXER+3v357LnCKMMccwIoSSJPGABwI4eHjAV4s84AW1UqlU/gCMT9FiGribiwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/8bbb5dc1c1f2e70a832b7406ee258491/7f61c/povertyrateus2010.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8bbb5dc1c1f2e70a832b7406ee258491/b5732/povertyrateus2010.webp 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/8bbb5dc1c1f2e70a832b7406ee258491/e17e5/povertyrateus2010.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8bbb5dc1c1f2e70a832b7406ee258491/39c09/povertyrateus2010.png 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/8bbb5dc1c1f2e70a832b7406ee258491/39c09/povertyrateus2010.png",
            "alt": "povertyrateus2010",
            "title": "povertyrateus2010",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "872px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADT0lEQVQozz2Sa2wUZRSG5xcGo8bEigbTYkha04qsgdgYEhCEqAnRhPjHiIaCWowFa00IIRRiKiJBKUhit4hWY7ES7YU1rfaiUXtJ7K4tvez91s7s7M7OXmd2di7fN983x8y28fw+zznvec/LIIQopaZJZEmmhOYzYmKVzWYyK7Eox7Lx+ArLstFILB5fSSaTJrGLEmKapmEYDEIIAApFyTk6fMsbGPDM/xMKCKUSm5fTklKUpEI2Q7DdQy0LAJK5vKSqAGAgxOgVeNy7WndlpL5z9LBryhkNdkxNN/a5D/5y1znvufxjf//4VFkpAUBaLNycnJz2+QBANwxGN2y4rWuceaOXOTmw6bzrut9/aW6u7vbSzsHlS4tLn7tn97Vf/2LMk02KB5q637r2XSjgSwlpW7ZRgZtbejYccm5oH2lyzXR4FpuHJ5/sdTt+Xn5myLt/wP38R4P73une/+nAQ609Q67fAYBQC9mydRu+fNV1z95rj14cvREJNH71J9M2dO+5O/d1TtYc6bn/yK2qg1dra09sPN2/u+NLtz98dma1PygANdc3z90N1+7qePhA5+7D3dte+mzzc59UvXDhgdduPL73Yv2OU0/UtzgaWqtf7Xrl6JW+2UXm44mjI14Ai8EYg13W3zNLh950PtJwbuuDbzuqju2sOeFwtDvqTz1dfXz75vcaNr3bWHN8wR3I6uhrb3ImWQQTr8OVL4BuoIm/lm//8Meu7e83PNayY0tr7dbWLXUfPOs4+dSe869/eFMrFxA2K/2VmzVNwxgjhAjGlJgW2GOWFsJn2r7ZVt38/bejP92ZCkfjpmW+3Dk4Nh8GAEwpXTNsLSRgUTYvyzrCCMklBQB4TujrHYbKLEIIAMz6YtPe6P9pqWzWdQArVSg19nle/DWSVVQTIwObuqZmc5lYNB4KhzmOK6sqtkyMUTAQzGayhJD1P1NixoVM+8RC03gwrWhASV6WDTvzFstxPM9bhBQN88K0LxAK+f3+SCQai8UlSV6XTamtZDUlRLkEAHDFoqLZARbFjCCkASAnaWe7xhI8z/OJVCqV4HlFKTOGYRBCbM+wWSqrYj6/5p+m65QQWZYlSaIWVUrqxG//iqIoCEIul8vl86qq/gcppbn/iQkt+QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/7f61c/fdicunbankedrateus.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/d00b9/fdicunbankedrateus.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/a8a2c/fdicunbankedrateus.webp 872w"],
            "sizes": "(max-width: 872px) 100vw, 872px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/e17e5/fdicunbankedrateus.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/5a190/fdicunbankedrateus.png 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/65654/fdicunbankedrateus.png 872w"],
            "sizes": "(max-width: 872px) 100vw, 872px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/c9b0498c65b8e56ef66c3518354a2cab/65654/fdicunbankedrateus.png",
            "alt": "fdicunbankedrateus",
            "title": "fdicunbankedrateus",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{` While there is definitely a correlation between people's income and banking status – you can see a lot of similarities between the maps – it’s clear that there isn't a one to one correlation. Look at the difference in California, Alabama, South Carolina, Tennessee and Kentucky to name a few. While these states don’t have the highest poverty numbers, they do have the highest numbers of unbanked people. If income were the primary driver of banking status (e.g. banked or unbanked), then we'd expect these maps to look exactly the same. Clearly we're not telling the whole story.`}</p>
    <p>{`CFSI’s `}<a parentName="p" {...{
        "href": "http://cfsinnovation.com/system/files/imported/managed_documents/prepaid_oct09_0001.pdf"
      }}>{`Underbanked Consumer Study`}</a>{` (PDF download) reveals that the primary reason unbanked people prefer prepaid to having a bank account is that they can "only spend the money they have" – e.g. they can't overdraft. The second, by a long shot, is that swiping a card is faster than writing a check. The third is that prepaid cards are accepted more places than checks. The cost of maintaining an account did show up on the list but it was only mentioned by 40% of unbanked (as compared to close to 60% who mentioned the overdraft issue).`}</p>
    <p>{`So the moral of the story you ask? People who don’t have bank accounts aren't necessarily poor. They are people who plainly don't see the value in having a bank account. Yes there's overlap but probably less overlap that you (or even I) might think.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      